/* General Body Styles */
body {
    margin: 0;
    font-family: 'Jersey 20', sans-serif;
    color: white;
    height: 100vh;
    overflow: hidden;
    position: relative;
}

body::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('background.jpeg');
    background-size: cover;
    background-position: center;
    filter: blur(8px); /* Subtle blur effect */
    background-color: rgba(0, 0, 0, 0.9); /* Darker background with more opacity */
    z-index: -1;
    animation: smoothDistortion 10s infinite alternate; /* Smooth distortion effect */
}

/* Smooth background distortion */
@keyframes smoothDistortion {
    0% {
        transform: scale(1) rotate(0deg);
    }
    50% {
        transform: scale(1.02) rotate(1deg); /* Slight distortion */
    }
    100% {
        transform: scale(1) rotate(0deg);
    }
}

/* Home Container */
.home-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: relative;
    text-align: center;
    overflow: hidden;
}

/* Apply font to inputs and buttons */
input, button {
    font-family: 'Jersey 20', sans-serif;
}

/* Input Fields */
.input, .password-input, .name-input {
    width: 350px; /* Increased width */
    padding: 15px;
    font-size: 1.2rem; /* Adjusted font size */
    margin: 10px 0;
    border-radius: 12px;
    border: 2px solid #FFD700; /* Golden border */
    background-color: rgba(255, 255, 255, 0.1); /* Slight transparent background */
    color: black; /* Black font color for inputs */
    transition: all 0.3s ease;
}

/* Focus Input */
.input:focus, .password-input:focus, .name-input:focus {
    outline: none;
    border-color: #FF4500; /* Focused input border color */
    background-color: rgba(255, 255, 255, 0.3); /* Lighter background when focused */
}

/* Buttons */
.button, .name-submit-btn, .password-submit-btn {
    padding: 15px 30px;
    background-color: #FFD700;
    color: white;
    border: none;
    border-radius: 25px; /* Rounded edges */
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    text-transform: uppercase; /* Make button text uppercase */
}

.button:hover, .name-submit-btn:hover, .password-submit-btn:hover {
    background-color: #FFCC00;
    transform: scale(1.05); /* Slight grow effect on hover */
}

/* Sound Control Icon */
.sound-toggle {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.sound-icon {
    width: 50px;
    height: 50px;
}

.title {
    font-family: 'Jersey 20', sans-serif;
    color: #FFD700;
    font-size: 3rem;
    text-transform: uppercase;
    letter-spacing: 5px;
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.7);
    margin-bottom: 40px;
}

.password-form, .name-form {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    justify-content: center;
    align-items: center;
    gap: 20px; /* Space between form elements */
}

.password-form-inner {
    display: flex;
    flex-direction: column; /* Stack the password input and button */
    gap: 10px; /* Add space between input and button */
}

.message {
    font-size: 1.2rem;
    color: #FFD700;
}

.secret-message {
    font-size: 1.5rem;
    color: green;
}

/* Contract Address Styling */
.contract-address {
    font-size: 1.2rem;
    color: white;
    cursor: pointer;
    margin-top: 20px;
    text-decoration: underline;
    opacity: 0.7; /* Initial opacity to make it less noticeable */
    transition: opacity 0.3s ease;
    position: relative;
}

/* Hover Effect for Contract Address */
.contract-address:hover {
    opacity: 1; /* Full opacity on hover */
}

/* Feedback text for copied status */
.copy-feedback {
    font-size: 1rem;
    color: #FFD700;
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    transition: opacity 0.3s ease;
    opacity: 0;
}

.copy-feedback.copied {
    opacity: 1;
}

/* Optional arrow next to contract address */
.contract-address span {
    opacity: 0; /* Hidden arrow by default */
    transition: opacity 0.3s ease;
    margin-left: 10px;
}

.contract-address:hover span {
    opacity: 1; /* Show arrow when hovered */
}

.welcome-message {
    font-size: 2rem;
    color: #FFD700;
    margin-top: 20px;
}

footer {
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
    color: white;
    font-size: 1rem;
}

/* Password Visibility Button */
.toggle-password-btn {
    background: none;
    border: none;
    color: #FFD700;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 10px;
    transition: color 0.3s ease;
  }
  
  .toggle-password-btn:hover {
    color: #FF4500;
  }

/* Contract Address and Twitter Handle Form Styling */
.contract-form {
    margin-top: 20px;
    padding: 15px;
    background-color: #f1f1f1;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .contract-input,
  .twitter-input {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .contract-input::placeholder,
  .twitter-input::placeholder {
    color: #aaa;
  }
  
  .contract-form h2 {
    color: #333;
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .x-section {
    position: fixed; /* Fixes the element relative to the viewport */
    top: 10px; /* 10px from the top */
    left: 10px; /* 10px from the left */
    cursor: pointer;
    z-index: 9999; /* Ensures the X is on top of other elements */
    font-size: 30px; /* Adjust the size of the X */
    font-weight: bold; /* Make the X bold */
    color: white; /* Color of the X */
    padding: 5px 10px; /* Optional: Add some padding for better clickability */
  }
  